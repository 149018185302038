import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Metafields } from '@/types/ecommerce.types';
import { RootState } from '@/store';
import { setValidation, setQualityByPass, setPendingUpload } from '@/store/upload/uploadSlice';
import { withQuery } from '../data/withQuery';
import Button from '../../templates/button/Button';
import CloseIcon from '../../templates/icons/CloseIcon';
import { getMetafield } from '@/utils/utils';
import * as S from './Resolution.styles';
import Overlay from '@/components/templates/overlay/Overlay';

type ResolutionDialogProps = {
  shopMetafields: Metafields;
};

const ResolutionDialog: React.FC<ResolutionDialogProps> = ({ shopMetafields }) => {
  const dispatch = useDispatch();
  const MAX_FILE_SIZE_IN_MB = Number(getMetafield('max_upload_size_mb', shopMetafields) ?? '100');
  const validation = useSelector((state: RootState) => state.upload.validation);
  const newType =
    validation.sizeIsOk === false ? 'size' : validation.qualityIsOk === false ? 'quality' : 'size';

  if (validation.sizeIsOk && validation.qualityIsOk) {
    return null;
  }
  return (
    <Overlay open={true} variant="light">
      <S.Wrapper
        role="alertdialog"
        aria-modal="true"
        aria-labelledby="resolutionModalTitle"
        aria-describedby="resolutionModalDesc"
      >
        {newType === 'size' && (
          <S.WrapperIcon
            onClick={() => {
              dispatch(
                setValidation({
                  sizeIsOk: true,
                  qualityIsOk: true,
                  position: undefined,
                })
              );
              dispatch(setPendingUpload(false));
            }}
          >
            <CloseIcon dark />
          </S.WrapperIcon>
        )}
        {newType === 'quality' && (
          <>
            <S.Heading id="resolutionModalTitle">Low resolution image</S.Heading>
            <S.Text id="resolutionModalDesc">
              This image is low resolution and could result in a blurry or pixelated print.
            </S.Text>
          </>
        )}
        {newType === 'size' && (
          <>
            <S.Heading id="resolutionModalTitle">File too large</S.Heading>
            <S.Text id="resolutionModalDesc">
              We limit direct image uploads to <strong>{MAX_FILE_SIZE_IN_MB}mb</strong> to ensure
              site performance and experience. Your image is larger... but don’t worry! Email us at
              hello@frameology.com and we will make arrangements to process your full resolution
              file.
            </S.Text>
          </>
        )}
        {newType === 'quality' && (
          <S.WrapperButton>
            <Button
              color="primary"
              format="outlined"
              title="That's Ok"
              onClick={() => {
                dispatch(setQualityByPass(true));
                dispatch(
                  setValidation({
                    sizeIsOk: true,
                    qualityIsOk: true,
                    position: undefined,
                    isLowRes: true,
                  })
                );
              }}
            />
            <Button
              color="primary"
              format="contained"
              title="Change photo"
              onClick={() => {
                dispatch(
                  setValidation({
                    sizeIsOk: true,
                    qualityIsOk: true,
                    position: undefined,
                    isLowRes: true,
                  })
                );
                dispatch(setPendingUpload(false));
                const uploadField = validation.position
                  ? (document.querySelector(
                      `.upload-control-${validation.position}`
                    ) as HTMLElement)
                  : document.getElementById('upload-field');
                uploadField?.click();
              }}
            />
          </S.WrapperButton>
        )}
      </S.Wrapper>
    </Overlay>
  );
};
ResolutionDialog.displayName = 'ResolutionDialog';
export default withQuery(ResolutionDialog);
